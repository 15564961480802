@font-face {
  font-display: swap;
  font-family: LunchBox;
  font-style: normal;
  font-weight: bold;
  src:
    url("@fonts/lunchbox/LunchBox-Bold.woff2") format("woff2"),
    url("@fonts/lunchbox/LunchBox-Bold.woff") format("woff"),
    url("@fonts/lunchbox/LunchBox-Bold.ttf") format("truetype");
}

/* @font-face { */

/*  font-family: Inter; */

/*  font-style: normal; */

/*  font-weight: 100; */

/*  font-display: swap; */

/*  src: url('@fonts/inter/Inter-Thin.woff2') format('woff2'), url('@fonts/inter/Inter-Thin.woff') format('woff'); */

/* } */

/* @font-face { */

/*  font-family: Inter; */

/*  font-style: italic; */

/*  font-weight: 100; */

/*  font-display: swap; */

/*  src: url('@fonts/inter/Inter-ThinItalic.woff2') format('woff2'), url('@fonts/inter/Inter-ThinItalic.woff') format('woff'); */

/* } */

/* @font-face { */

/*  font-family: Inter; */

/*  font-style: normal; */

/*  font-weight: 200; */

/*  font-display: swap; */

/*  src: url('@fonts/inter/Inter-ExtraLight.woff2') format('woff2'), url('@fonts/inter/Inter-ExtraLight.woff') format('woff'); */

/* } */

/* @font-face { */

/*  font-family: Inter; */

/*  font-style: italic; */

/*  font-weight: 200; */

/*  font-display: swap; */

/*  src: url('@fonts/inter/Inter-ExtraLightItalic.woff2') format('woff2'), url('@fonts/inter/Inter-ExtraLightItalic.woff') format('woff'); */

/* } */

/* @font-face { */

/*  font-family: Inter; */

/*  font-style: normal; */

/*  font-weight: 300; */

/*  font-display: swap; */

/*  src: url('@fonts/inter/Inter-Light.woff2') format('woff2'), url('@fonts/inter/Inter-Light.woff') format('woff'); */

/* } */

/* @font-face { */

/*  font-family: Inter; */

/*  font-style: italic; */

/*  font-weight: 300; */

/*  font-display: swap; */

/*  src: url('@fonts/inter/Inter-LightItalic.woff2') format('woff2'), url('@fonts/inter/Inter-LightItalic.woff') format('woff'); */

/* } */

/* @font-face { */

/*  font-family: Inter; */

/*  font-style: normal; */

/*  font-weight: 400; */

/*  font-display: swap; */

/*  src: url('@fonts/inter/Inter-Regular.woff2') format('woff2'), url('@fonts/inter/Inter-Regular.woff') format('woff'); */

/* } */

/* @font-face { */

/*  font-family: Inter; */

/*  font-style: italic; */

/*  font-weight: 400; */

/*  font-display: swap; */

/*  src: url('@fonts/inter/Inter-Italic.woff2') format('woff2'), url('@fonts/inter/Inter-Italic.woff') format('woff'); */

/* } */

/* @font-face { */

/*  font-family: Inter; */

/*  font-style: normal; */

/*  font-weight: 500; */

/*  font-display: swap; */

/*  src: url('@fonts/inter/Inter-Medium.woff2') format('woff2'), url('@fonts/inter/Inter-Medium.woff') format('woff'); */

/* } */

/* @font-face { */

/*  font-family: Inter; */

/*  font-style: italic; */

/*  font-weight: 500; */

/*  font-display: swap; */

/*  src: url('@fonts/inter/Inter-MediumItalic.woff2') format('woff2'), url('@fonts/inter/Inter-MediumItalic.woff') format('woff'); */

/* } */

/* @font-face { */

/*  font-family: Inter; */

/*  font-style: normal; */

/*  font-weight: 600; */

/*  font-display: swap; */

/*  src: url('@fonts/inter/Inter-SemiBold.woff2') format('woff2'), url('@fonts/inter/Inter-SemiBold.woff') format('woff'); */

/* } */

/* @font-face { */

/*  font-family: Inter; */

/*  font-style: italic; */

/*  font-weight: 600; */

/*  font-display: swap; */

/*  src: url('@fonts/inter/Inter-SemiBoldItalic.woff2') format('woff2'), url('@fonts/inter/Inter-SemiBoldItalic.woff') format('woff'); */

/* } */

/* @font-face { */

/*  font-family: Inter; */

/*  font-style: normal; */

/*  font-weight: 700; */

/*  font-display: swap; */

/*  src: url('@fonts/inter/Inter-Bold.woff2') format('woff2'), url('@fonts/inter/Inter-Bold.woff') format('woff'); */

/* } */

/* @font-face { */

/*  font-family: Inter; */

/*  font-style: italic; */

/*  font-weight: 700; */

/*  font-display: swap; */

/*  src: url('@fonts/inter/Inter-BoldItalic.woff2') format('woff2'), url('@fonts/inter/Inter-BoldItalic.woff') format('woff'); */

/* } */

/* @font-face { */

/*  font-family: Inter; */

/*  font-style: normal; */

/*  font-weight: 800; */

/*  font-display: swap; */

/*  src: url('@fonts/inter/Inter-ExtraBold.woff2') format('woff2'), url('@fonts/inter/Inter-ExtraBold.woff') format('woff'); */

/* } */

/* @font-face { */

/*  font-family: Inter; */

/*  font-style: italic; */

/*  font-weight: 800; */

/*  font-display: swap; */

/*  src: url('@fonts/inter/Inter-ExtraBoldItalic.woff2') format('woff2'), url('@fonts/inter/Inter-ExtraBoldItalic.woff') format('woff'); */

/* } */

/* @font-face { */

/*  font-family: Inter; */

/*  font-style: normal; */

/*  font-weight: 900; */

/*  font-display: swap; */

/*  src: url('@fonts/inter/Inter-Black.woff2') format('woff2'), url('@fonts/inter/Inter-Black.woff') format('woff'); */

/* } */

/* @font-face { */

/*  font-family: Inter; */

/*  font-style: italic; */

/*  font-weight: 900; */

/*  font-display: swap; */

/*  src: url('@fonts/inter/Inter-BlackItalic.woff2') format('woff2'), url('@fonts/inter/Inter-BlackItalic.woff') format('woff'); */

/* } */
