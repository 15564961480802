:root {
  --white: 0 0% 100%;
  --black: 0 0% 0%;
  --gray: 220 9% 46%;
  --background: 0 0% 100%;
  --foreground: 11, 20%, 17%;
  --heading: 11, 20%, 17%;
  --separator: 0 0% 73%;
  --selected: 209 61% 92%;
  --input-border: 0 0% 73%;
  --primary: 11, 20%, 17%;
  --primary-foreground: 0 0% 100%;
  --secondary: 25, 95%, 53%;
  --secondary-foreground: 0 0% 100%;
  --secondary-50: 33, 100%, 96%;
  --secondary-100: 34, 100%, 92%;
  --secondary-200: 32, 98%, 83%;
  --secondary-300: 31, 97%, 72%;
  --secondary-400: 27, 96%, 61%;
  --secondary-600: 21, 90%, 48%;
  --secondary-700: 17, 88%, 40%;
  --secondary-800: 15, 79%, 34%;
  --secondary-900: 15, 75%, 28%;
  --successful: 142, 71%, 45%;
  --successful-foreground: 0 0% 100%;
  --successful-light: 149 80% 90%;
  --warning: 38 92% 50%;
  --warning-foreground: 0 0% 100%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 0 0% 100%;
  --informative: 199 100% 33%;
  --informative-foreground: 0 0% 100%;
  --muted: 240, 6%, 90%;
  --muted-foreground: 11, 20%, 17%;
  --wp--preset--spacing--50: theme("spacing.1");
  --wp--preset--spacing--60: theme("spacing.2");
  --wp--preset--spacing--70: theme("spacing.3");
  --wp--preset--spacing--80: theme("spacing.4");
  --wp--preset--spacing--90: theme("spacing.5");
  --wp--preset--spacing--100: theme("spacing.6");
  --wp--preset--spacing--110: theme("spacing.7");
  --wp--preset--spacing--120: theme("spacing.8");
  --wp--preset--spacing--130: theme("spacing.9");
  --wp--preset--spacing--140: theme("spacing.10");
  --wp--preset--spacing--150: theme("spacing.11");
  --wp--preset--spacing--160: theme("spacing.12");
}
