@layer components {
  .wp-block-image {
    &.is-style-rounded {
      img {
        @apply rounded-full;
      }
    }
  }

  .wp-block-buttons {
    @apply flex flex-wrap gap-4;
  }

  ol.wp-block-list {
    list-style: decimal;
  }

  ul.wp-block-list {
    list-style: disc;
  }
}
